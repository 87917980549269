import * as React from 'react';
import { Body, Col, Grid, GridSettings, H1, Row, Separator } from '@web/atomic';
import { MatchMedia } from '@web/atomic/legacy/obj.match-media';
import LargeSeparatorRow from '../components/atm.large-separator-row/large-separator-row.component';
import SvgErro404 from '@web/atomic/atm.svg-icon/erro404.component';
import IndexLayout from '../components/org.layout/layout.component';
import { hasWindow } from '@web/utils/platform';
import { PageProps } from '../utils/local-types';
import { GatsbyButton } from '@components/atm.button/button.component';

const NotFoundPage: React.FunctionComponent<PageProps> = (props) => {
  const handleBackClick = () => {
    if (hasWindow()) {
      window.history.back();
    }
    return null;
  };
  return (
    <IndexLayout {...props}>
      <Grid>
        <LargeSeparatorRow />

        <Row reverse>
          <Col xs={12} mdOffset={2} md={6}>
            <SvgErro404 width="100%" />
            <MatchMedia defaultMinWidth={`${GridSettings.flexboxgrid.breakpoints.md}em`}>{(md) => md && <Separator />}</MatchMedia>
          </Col>

          <Col xs={12} md={4}>
            <Row mb>
              <Col xs={12}>
                <H1>{'Página não encontrada'}</H1>
                <Body>O endereço digitado não existe.</Body>
              </Col>
            </Row>
            <Row>
              <Col xs={6}>
                <GatsbyButton kind="secondary" expanded onClick={handleBackClick}>
                  Voltar
                </GatsbyButton>
              </Col>
              <Col xs={6}>
                <GatsbyButton kind="secondary" expanded to={'/'}>
                  Ir para o início
                </GatsbyButton>
              </Col>
            </Row>
          </Col>
        </Row>
        <LargeSeparatorRow />
      </Grid>
    </IndexLayout>
  );
};

export default NotFoundPage;
